//Import
@import "variables";
@import "typography";

//Extended Stepy Button Styles
.stepyBtn-style
{
  background-color: $system-color;
  border-color: $system-color;
  color: $main-secondary-color;
  font-family: $main-bold;
  letter-spacing: $alpha-space;

  &:hover, &:focus, &:active
  {
    color: $main-secondary-color!important;
    background-color: $system-color!important;
    border-color: $system-color!important;

    -webkit-transition: $-webkit-transition;
    -moz-transition: $-moz-transition;
    -o-transition: $-o-transition;
    -ms-transition: $-ms-transition;
    transition: $transition;
  }
}

.stepyBtn-back-style
{
  border: 2px solid $system-color;
  color: $system-color;
  font-family: $main-bold;
  letter-spacing: $alpha-space;

  &:hover, &:focus, &:active
  {
    border-color: $system-color!important;

    -webkit-transition: $-webkit-transition;
    -moz-transition: $-moz-transition;
    -o-transition: $-o-transition;
    -ms-transition: $-ms-transition;
    transition: $transition;
  }
}

//Comprehensive Pages

//Interview
.selectStyle
{
  border: 2px solid $system-color;
  color: $system-color;
  font-family: $main-bold;
  letter-spacing: $alpha-space;

  &:hover, &:focus, &:active
  {
    border-color: #458c47!important;
    color: #458c47;

    -webkit-transition: $-webkit-transition;
    -moz-transition: $-moz-transition;
    -o-transition: $-o-transition;
    -ms-transition: $-ms-transition;
    transition: $transition;
  }
}


//Basement
.addImage, .addCustom
{
  border: 2px solid $system-color;
  color: $system-color;
  font-family: $main-bold;
  letter-spacing: $alpha-space;

  &:hover, &:focus, &:active
  {
    border-color: $system-color!important;

    -webkit-transition: $-webkit-transition;
    -moz-transition: $-moz-transition;
    -o-transition: $-o-transition;
    -ms-transition: $-ms-transition;
    transition: $transition;
  }
}